import moment from 'moment';
import { routerApp, roundToPlaces, enableOnPremise } from '../app.module';

routerApp.controller('seaReportController', ['$rootScope', '$stateParams', '$scope', '$timeout', 'ReportService', 'ModelsApiService', 
    function($rootScope, $stateParams, $scope, $timeout, ReportService, ModelsApiService) {

    $rootScope.selectedLevels = ['vessel-reports', 'create-new-report', 'sea-report'];

    if ($stateParams.report != undefined) {
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.report = $stateParams.report;
        $scope.viewReport = $stateParams.viewReport;

        if ($scope.viewReport) {
            $timeout(function() {
                $scope.$broadcast('validate-form', { tabs: ['operational', 'position', 'power', 'engine', 'consumptions', 'stock'] });
                $('.wrapper').scrollTop($stateParams.scrollLocation);
            }, 3000);
        }
    } else {
        // this just gets the newly edited report from the service, always gets it
        // as soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready.
        $scope.report = ReportService.getVesselReport();
        $scope.report._cls = "Report.SeaReport";
        $scope.report.bunker.took_fuel_oil = false;
        // update service with report type
        ReportService.setVesselReport($scope.report);
        $rootScope.selectedLevels = ['vessel-reports', 'edit-report'];
    }

    $scope.$on('vessel-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.report = ReportService.getVesselReport();
        }
    });

    $scope.$watch('report.operational.arriving', function(n, o, scope) {
        if (n && n == true) {
            $scope.report.operational.eosp = $scope.report.operational.report_to;
            scope.report.operational.departing = false;
        }
    });

    $scope.$watch('report.operational.eosp', function(newValue) {
        if (newValue && $scope.report.operational.arriving) {
            $scope.report.operational.report_to = newValue;
        }
    });

    $scope.$watch('report.operational.departing', function(n, o, scope) {
        if (n && n == true) {
            scope.report.operational.arriving = false;
        }
    });

    $scope.engineTabEnabled = true;
    $scope.$watchGroup(['report.operational.arriving', 'report.operational.departing', 'report.operational.report_period'], function(n, o, scope) {
        if (n[0] || n[1] || $scope.isDieselElectricPropulsion() || n[2] < 12) {
            $scope.engineTabEnabled = false;
        } else {
            $scope.engineTabEnabled = true;
        }
    });

    $scope.$watchGroup(['report.consumption.cyl_oil_code', 'report.engine.main_engines[0].main_engine_load', 'report.consumption.cyl_oil_sloc'], function(newValues) {
        var oilCode = newValues[0];
        var engineLoad = newValues[1];
        var engineType = $scope.vesselSpecs && $scope.vesselSpecs.information && $scope.vesselSpecs.information.engine_type;
        var sloc = newValues[2];

        // Validate only when (Oil Code 1 == Normal):
        if (oilCode == '1' && engineLoad >= 25 && engineType != 'four_stroke_engine') {
            if (!$scope.report.warningMessages) {
                $scope.report.warningMessages = {};
            }
            if (sloc < 0.5 || sloc > 2) {
                $scope.report.warningMessages['report.consumption.cyl_oil_consumption'] = 'Actual Consumption (L) should result in an SLOC greater than 0.5 g/kWh and less than 2.0 g/kWh.';
            } else {
                $scope.report.warningMessages['report.consumption.cyl_oil_consumption'] = null;
            }
        }
    });
    
    $scope.shouldEnableRA = true;
    $scope.$watchGroup(['user', 'report'], function(newValues) {
        var user = newValues[0];
        var report = newValues[1];
        $scope.shouldEnableRA = !(user.role == 'ship_user' && report.status != 'completed') && (user.features?.reporting?.result_enabled == undefined || user.features?.reporting?.result_enabled) && !$rootScope.selectedVessel.ciiReportingOnlyEnabled && !enableOnPremise;
    });

    $scope.initActiveTab = function() {
        if (['operational', 'position', 'power', 'engine', 'consumptions', 'stock', 'cii'].indexOf($stateParams.activeTab) != -1) {
            return $stateParams.activeTab;
        } else if ($rootScope.selectedVessel.ciiReportingOnlyEnabled == true) {
            return 'cii';
        } else {
            return 'operational';
        }
    };

    $scope.$watch('report.operational.ballast_code', function(newValue) {
        $scope.report.warningMessages['report.operational.ballast_code'] = !newValue ? 'Ballast Code should be selected' : null;
    });

    $scope.register($scope, 'aux_engine_diff_rh_excess');

    $scope.shouldRequireShaftPower = function() {
        var shaftGenVessel = $scope.selectedVessel.dualEngine || $scope.selectedVessel.name == 'M.V. Viking Queen';
        var shaftRHgt0 = $scope.report.power.shaft_rh > 0;
        var requireShaftPower = (!($scope.report.power.aux_engine_total_power>0) && $scope.formConfiguration['power.generatorEngine']);
        return shaftRHgt0 || requireShaftPower || shaftGenVessel;
    };

    $scope.auxEngineRHSum = function() {
        return [$scope.report.power.aux_engine_1_diff_rh, $scope.report.power.aux_engine_2_diff_rh, $scope.report.power.aux_engine_3_diff_rh, $scope.report.power.aux_engine_4_diff_rh].sum();
    };

    $scope.boilerRHSum = function() {
        return [$scope.report.power.aux_boiler_diff_rh, $scope.report.power.aux_boiler_2_diff_rh].sum();
    };

    $scope.isFirstSeaReportAfterPortReport = function() {
        for (var i = 0; i < $scope.reports.length; i++) {
            var report = $scope.reports[i];
            if (report.reportNumber >= $scope.report.report_number) continue;

            if (!$scope.isOfReportType($scope.report, 'Report.SeaReport')) return false;
            if (report.reportType == 'Sea' || report.reportType == 'Departure') return false;
            if (report.reportType == 'Port') return true;
        }
        return false;
    };

    $scope.showSeaReportCOSP = function() {
        // Show for first Sea Report after Port Report.
        return $scope.isFirstSeaReportAfterPortReport() && !$scope.report.operational.arriving;
    };

    $scope.showSeaReportEOSP = function() {
        // Show for Sea Report if arrival checkbox is checked
        return !!$scope.report.operational.arriving;
    };

    $scope.portFieldName = function(){
        return (!!$scope.report.operational.arriving ? "Arrival Port" : "Next Port");
    };

    $scope.$watchGroup(['report.power.avg_tcrpm_1', 'report.power.avg_tcrpm_2', 'report.power.main_engine_1_rpm', 'report.engine.main_engines[0].tcrpm_reported', 'report.engine.main_engines[0].tcrpm_2_reported'], function(n, o, $scope) {
        if (!$scope.vesselSpecs) return;

        var avgTCRPM  = !$scope.vesselSpecs.information.has_torsionmeter ? n[0] : n[3];
        var avgTCRPM2 = !$scope.vesselSpecs.information.has_torsionmeter ? n[1] : n[4];
        var meRPM = n[2];

        if (meRPM != undefined && (avgTCRPM != undefined || avgTCRPM2 != undefined)) {

            ModelsApiService.getTCRPMModel(avgTCRPM, avgTCRPM2).then(function(response) {
                var tcrpmPower = response.data.data.tcrpmPower;

                // Use old calculation for reports before June 1st.
                if (moment($scope.report.operational.report_to, 'DD/MM/YYYY HH:mm') < moment('01/06/2019 00:00', 'DD/MM/YYYY HH:mm')) {
                    $scope.report.power.tcrpm_power = tcrpmPower;
                    return;
                }

                ModelsApiService.getMeRpmModel(meRPM).then(function(response) {
                    var meRPMPower = response.data.data.meRPMPower;
                    if (tcrpmPower && meRPMPower) {
                        var mean = roundToPlaces([tcrpmPower, meRPMPower].mean(), 1);
                        $scope.report.power.tcrpm_power = mean;
                    } else {
                        $scope.report.power.tcrpm_power = roundToPlaces(tcrpmPower || meRPMPower, 1);
                    }
                });
            });
        } else {
            $scope.report.power.tcrpm_power = null;
        }
    });

    $scope.$watch('report.operational.number_of_reefer_containers_20_ft', function(newValue) {
        if ($scope.carryOverReport && $scope.carryOverReport.operational) {
            $scope.report.warningMessages['report.operational.number_of_reefer_containers_20_ft'] = newValue != $scope.carryOverReport.operational.number_of_reefer_containers_20_ft && $scope.carryOverReport.operational.number_of_reefer_containers_20_ft != undefined
            ? "Value does not match previous report's entry (" + $scope.carryOverReport.operational.number_of_reefer_containers_20_ft + ")"
            : null;
        }
    });
    $scope.$watch('report.operational.number_of_reefer_containers_40_ft', function(newValue) {
        if ($scope.carryOverReport && $scope.carryOverReport.operational) {
            $scope.report.warningMessages['report.operational.number_of_reefer_containers_40_ft'] = newValue != $scope.carryOverReport.operational.number_of_reefer_containers_40_ft && $scope.carryOverReport.operational.number_of_reefer_containers_40_ft != undefined
            ? "Value does not match previous report's entry (" + $scope.carryOverReport.operational.number_of_reefer_containers_40_ft + ")"
            : null;
        }
    });
    $scope.$watch('report.operational.number_of_normal_containers_20_ft', function(newValue) {
        if ($scope.carryOverReport && $scope.carryOverReport.operational) {
            $scope.report.warningMessages['report.operational.number_of_normal_containers_20_ft'] = newValue != $scope.carryOverReport.operational.number_of_normal_containers_20_ft && $scope.carryOverReport.operational.number_of_normal_containers_20_ft != undefined
            ? "Value does not match previous report's entry (" + $scope.carryOverReport.operational.number_of_normal_containers_20_ft + ")"
            : null;
        }
    });
    $scope.$watch('report.operational.number_of_normal_containers_40_ft', function(newValue) {
        if ($scope.carryOverReport && $scope.carryOverReport.operational) {
            $scope.report.warningMessages['report.operational.number_of_normal_containers_40_ft'] = newValue != $scope.carryOverReport.operational.number_of_normal_containers_40_ft && $scope.carryOverReport.operational.number_of_normal_containers_40_ft != undefined
            ? "Value does not match previous report's entry (" + $scope.carryOverReport.operational.number_of_normal_containers_40_ft + ")"
            : null;
        }
    });
    $scope.formatDate = function(date) {
        return date.split(" ")[0]
    };
}]);
