import { routerApp } from '../app.module';
import angular from 'angular';

routerApp.controller('manReportController', ['$rootScope', '$scope', '$stateParams', '$timeout', 'ReportService', 
function($rootScope, $scope, $stateParams, $timeout, ReportService) {

    $rootScope.selectedLevels = ['vessel-reports', 'create-new-report', 'man-report'];

    if ($stateParams.report != undefined) {
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.report = $stateParams.report;
        $scope.viewReport = $stateParams.viewReport;

        if ($scope.viewReport) {
            $timeout(function() {
                $scope.$broadcast('validate-form', { tabs: ['operational', 'position', 'power', 'consumptions'] });
                $('.wrapper').scrollTop($stateParams.scrollLocation);
            }, 3000);
        }
    } else {
        // this just gets the newly edited report from the service, always gets it
        // as soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready
        $scope.report = ReportService.getVesselReport();
        $scope.report._cls = "Report.ManeuveringReport";
        $scope.report.bunker.took_fuel_oil = false;
        // update service with report type
        ReportService.setVesselReport($scope.report);
        $rootScope.selectedLevels = ['vessel-reports', 'edit-report'];
    }

    $scope.$on('vessel-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.report = ReportService.getVesselReport();
        }
    });

    // handle _calculated stock values for maneuvering reports
    angular.forEach(['hfo', 'lfo', 'mgo', 'mdo', 'b10lfo', 'b10mgo', 'biolfo','biomgo','ulsfo2020','ulslfo2020','ulsmdo2020','ulsmgo2020','vlsfo2020','vlslfo2020','lpgp','lpgb','lng','methanol','ethanol','other'], function(grade) {
        $scope.$watchGroup([
            'report.consumption.total_consumption_' + grade,
            'report.stock.previous_' + grade,
            'carryOverReport',
        ], function(newValues, oldValues, scope) {
            var currentConsumption = newValues[0] || 0;
            var previousStock = newValues[1];

            if (previousStock == undefined) return;
            var calculatedPreviousStock = (scope.carryOverReport && scope.carryOverReport.stock && scope.carryOverReport.stock[grade + '_calculated']);
            if (previousStock == 0 && calculatedPreviousStock > 0) {
                previousStock = calculatedPreviousStock;
            }
            scope.report.stock[grade + '_calculated'] = previousStock - currentConsumption;
        });
    });

    $scope.initActiveTab = function() {
        if (['operational', 'position', 'power', 'consumptions','cii'].indexOf($stateParams.activeTab) != -1) {
            return $stateParams.activeTab;
        } else if ($rootScope.selectedVessel.ciiReportingOnlyEnabled == true) {
            return 'cii';
        } else {
            return 'operational';
        }
    };

}]);
